import { ensureShopifyGid, shopifyNumericIdFromGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export async function loadSellingPlanGroupProductsAndVariants(sellingPlanGroupShopifyId: string): Promise<{
  productIds: string[];
  variantIds: string[];
}> {
  const result = await typedFrontendVendorApi.getReq("/selling-plan-groups/:id", {
    params: {
      id: shopifyNumericIdFromGid(sellingPlanGroupShopifyId),
    },
  });

  if (result.type !== "success") {
    throw new Error("Error looking up Selling Plan Group by Shopify Id");
  }

  return {
    productIds: result.body.productIds.map(p => ensureShopifyGid("Product", p)),
    variantIds: result.body.productVariantIds.map(p => ensureShopifyGid("ProductVariant", p)),
  };
}
